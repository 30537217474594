import { Button, Input, Select, Statistic, Table } from "antd";
import { useEvaluationTableQuery } from "generated/graphql";
import Publish from "components/publish-questions";
import Link from "next/link";
import React from "react";

const display = {
  fail: "Fail",
  "ready-to-evaluate": "Ready to evaluate",
  pass: "Pass",
  "in-progress": "In progress",
  "cqs-to-be-published": "Ready to publish CQs",
  clarification: "Requires Clarification",
  published: "Published",
};

function ClarificationQuestionButton({
  uuid,
  applicationName,
  status,
  moderation = false,
}) {
  const [visible, setVisible] = React.useState(false);
  const closeModal = () => {
    setVisible(false);
  };
  return (
    <div>
      {status === "cqs-to-be-published" ? (
        <Button
          shape="round"
          size="small"
          className="m-0"
          onClick={() => {
            setVisible(true);
          }}
        >
          {display[status]}
        </Button>
      ) : (
        display[status] || status
      )}
      <Publish
        visible={visible}
        closeModal={closeModal}
        questionnaire={{ uuid, name: applicationName }}
        moderation={moderation}
      />
    </div>
  );
}

export default function EvaluationTable() {
  const [variables, setVariables] = React.useState({
    first: 10,
    submitted: true,
  });
  const {
    data,
    networkStatus,
    fetchMore,
    ...options
  } = useEvaluationTableQuery({
    variables,
    notifyOnNetworkStatusChange: true,
  });
  React.useEffect(() => {
    options?.refetch(variables);
  }, [variables]);

  return (
    <div className="flex flex-col">
      <Input.Search
        //@ts-ignore
        onSearch={(name) => setVariables({ ...variables, name })}
        loading={networkStatus < 7}
        className="mb-4 w-64"
      />
      <Table
        loading={networkStatus < 7}
        columns={[
          {
            title: "Application questionnaire",
            render: ({ node }) => (
              <Link
                href="/compliance-check/[uuid]/section/[section]"
                as={`/compliance-check/${node.uuid}/section/1`}
              >
                <a>{node.user.applicationName}</a>
              </Link>
            ),
          },
          {
            title: () => (
              <div>
                <div>Compliance Check status</div>
                <Select
                  className="w-full"
                  onChange={(ccStatus) => {
                    //@ts-ignore
                    setVariables({ ...variables, ccStatus });
                  }}
                >
                  <Select.Option value="pass">Pass</Select.Option>
                  <Select.Option value="fail">Fail</Select.Option>
                  <Select.Option value="ready-to-evaluate">
                    Ready to evaluate
                  </Select.Option>
                  <Select.Option value="in-progress">In progress</Select.Option>
                  <Select.Option value="ready-for-moderation">
                    Ready for moderation
                  </Select.Option>
                  <Select.Option value="published">CQs published</Select.Option>
                  <Select.Option value="cqs-to-be-published">
                    CQs to be published
                  </Select.Option>
                </Select>
              </div>
            ),
            render: ({ node }) => (
              <ClarificationQuestionButton
                uuid={node?.uuid}
                status={node?.ccStatus}
                applicationName={node?.user?.applicationName}
              />
            ),
          },
          {
            title: () => (
              <div>
                <div>Technical Evaluation status</div>
                <Select
                  className="w-full"
                  onChange={(teStatus) => {
                    //@ts-ignore
                    setVariables({ ...variables, teStatus });
                  }}
                  allowClear
                >
                  <Select.Option value="pass">Pass</Select.Option>
                  <Select.Option value="fail">Fail</Select.Option>
                  <Select.Option value="ready-to-evaluate">
                    Ready to evaluate
                  </Select.Option>
                  <Select.Option value="in-progress">In progress</Select.Option>
                  <Select.Option value="ready-for-moderation">
                    Ready for moderation
                  </Select.Option>
                  <Select.Option value="published">CQs published</Select.Option>
                  <Select.Option value="cqs-to-be-published">
                    CQs to be published
                  </Select.Option>
                </Select>
              </div>
            ),
            render: ({ node }) => (
              <ClarificationQuestionButton
                uuid={node?.uuid}
                status={node?.teStatus}
                applicationName={node?.user?.applicationName}
                moderation
              />
            ),
          },
          {
            title: () => (
              <div>
                <div>Financial Evaluation status</div>
                <Select
                  className="w-full"
                  onChange={(feStatus) => {
                    //@ts-ignore
                    setVariables({ ...variables, feStatus });
                  }}
                >
                  <Select.Option value="pass">Pass</Select.Option>
                  <Select.Option value="fail">Fail</Select.Option>
                  <Select.Option value="ready-to-evaluate">
                    Ready to evaluate
                  </Select.Option>
                  <Select.Option value="in-progress">In progress</Select.Option>
                  <Select.Option value="ready-for-moderation">
                    Ready for moderation
                  </Select.Option>
                  <Select.Option value="published">CQs published</Select.Option>
                  <Select.Option value="cqs-to-be-published">
                    CQs to be published
                  </Select.Option>
                </Select>
              </div>
            ),
            render: ({ node }) => <div>{display[node?.feStatus]}</div>,
          },
          {
            title: () => (
              <div>
                <div>Overall status</div>
                <Select
                  className="w-full"
                  onChange={(overallStatus) => {
                    //@ts-ignore
                    setVariables({ ...variables, overallStatus });
                  }}
                >
                  <Select.Option value="pass">Pass</Select.Option>
                  <Select.Option value="fail">Fail</Select.Option>
                  <Select.Option value="ready-to-evaluate">
                    Ready to evaluate
                  </Select.Option>
                  <Select.Option value="in-progress">In progress</Select.Option>
                  <Select.Option value="ready-for-moderation">
                    Ready for moderation
                  </Select.Option>
                  <Select.Option value="published">CQs published</Select.Option>
                  <Select.Option value="cqs-to-be-published">
                    CQs to be published
                  </Select.Option>
                </Select>
              </div>
            ),
            render: ({ node }) => <div>{display[node?.overallStatus]}</div>,
          },
        ]}
        rowKey={({ node }) => node.uuid}
        dataSource={data?.questionnaires?.edges}
        pagination={false}
      />

      <Statistic
        className="self-end"
        value={data?.questionnaires?.edges?.length}
        suffix={`/ ${data?.questionnaires?.totalCount || 0}`}
      />
      <Button
        className="self-center"
        disabled={!data?.questionnaires?.pageInfo?.hasNextPage}
        onClick={() => {
          fetchMore({
            variables: {
              after: data?.questionnaires?.pageInfo?.endCursor,
            },
          });
        }}
      >
        Load more
      </Button>
    </div>
  );
}
