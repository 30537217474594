import React from "react";
import {
  Document,
  Page,
  Text,
  StyleSheet,
  View,
  PDFDownloadLink,
  Image,
  pdf,
} from "@react-pdf/renderer";
import moment from "moment";
import FileSaver from "file-saver";
import { Question } from "generated/graphql";
import { Button, message, Tooltip } from "antd";
import { useDeadline } from "context/deadline-context";

const BORDER_COLOR = "#d8d8d8";
const BORDER_STYLE = "solid";

const styles = StyleSheet.create({
  // General
  page: {
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 72,
    paddingBottom: 72,
    fontFamily: "Helvetica",
  },
  image: {
    position: "absolute",
    // right: 48,
    right: -65,
    top: 15,
    width: 55,
  },
  flex: {
    display: "flex",
  },

  // Typography
  h1: {
    fontFamily: "Helvetica-Bold",
    fontSize: 12,
    paddingBottom: 16,
  },
  h2: {
    fontFamily: "Helvetica-Bold",
    fontSize: 11,
    paddingBottom: 12,
    paddingTop: 12,
    lineHeight: 1.5,
  },
  h3: {
    fontFamily: "Helvetica-Bold",
    fontSize: 10,
    paddingBottom: 6,
    lineHeight: 1.5,
  },
  bold: {
    fontSize: 10,
    fontFamily: "Helvetica-Bold",
    padding: "12px 0 8px",
  },
  paragraph: {
    fontSize: 8,
    lineHeight: 1.5,
    paddingBottom: 8,
    color: "dimgrey",
  },
  label: {
    position: "absolute",
    top: 4,
    left: 8,
    fontSize: 8,
  },
  lph: {
    position: "absolute",
    top: 45,
    right: 56,
    fontSize: 7,
    fontFamily: "Helvetica-Bold",
  },
  smallTitle: {
    position: "absolute",
    top: 45,
    left: 40,
    fontSize: 8,
  },

  // Table
  table: {
    //@ts-ignore
    display: "table",
    width: "auto",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginBottom: 24,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCell: {
    margin: 5,
    marginTop: 17,
    marginLeft: 8,
    fontSize: 10,
    marginBottom: 2,
  },
  answerTableCell: {
    margin: 5,
    marginLeft: 8,
    fontSize: 10,
    lineHeight: 1.5,
    marginBottom: 2,
    width: "97%",
  },
  tableCol: {
    width: "50%",
    height: 34,
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },

  // Answers
  answer: {
    width: "100%",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    marginBottom: 18,
  },
  date: {
    width: 70,
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    marginBottom: 18,
  },
});

const Header = () => {
  return (
    <View
      fixed
      style={{ position: "absolute", top: 0, left: 0, width: "100%" }}
      render={({ pageNumber }) =>
        pageNumber !== 1 ? (
          <View style={{ width: "100%" }}>
            <Text style={{ ...styles.smallTitle }}>
              Dom Care AQP e-procurement: Submission copy
            </Text>
            <View style={{ position: "absolute", right: 20 }}>
              <Image
                style={styles.image}
                src="https://carepulse.co.uk/static/carepulse_generics/img/200px-NHS-Logo.svg.png"
              />
              <Text style={styles.lph}>London Purchased Healthcare Team</Text>
            </View>
            <View
              style={{
                width: "100%",
                borderStyle: BORDER_STYLE,
                borderColor: BORDER_COLOR,
                borderWidth: 1,
                borderTopWidth: 0,
                borderLeftWidth: 0,
                borderRightWidth: 0,
                position: "absolute",
                top: 56,
                left: 40,
              }}
            />
          </View>
        ) : (
          <View style={{ position: "absolute", right: 20 }}>
            <Image
              style={styles.image}
              src="https://carepulse.co.uk/static/carepulse_generics/img/200px-NHS-Logo.svg.png"
            />
            <Text style={styles.lph}>London Purchased Healthcare Team</Text>
          </View>
        )
      }
    />
  );
};

const Footer = () => {
  return (
    <Text
      style={{
        position: "absolute",
        width: "100%",
        left: 40,
        bottom: 40,
        textAlign: "center",
        fontSize: 8,
      }}
      fixed
      render={({ pageNumber, totalPages }) =>
        `Page ${pageNumber} / ${totalPages}`
      }
    />
  );
};

const RegistrationDetailsTable = ({ user, submissionDate }) => {
  return (
    <View style={styles.table}>
      <View style={styles.tableRow}>
        <View style={styles.tableCol}>
          <Text style={styles.label}>Name</Text>
          <Text style={styles.tableCell}>{user?.user?.fullName}</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.label}>Email</Text>
          <Text style={styles.tableCell}>{user?.user?.email}</Text>
        </View>
      </View>
      <View style={styles.tableRow}>
        <View style={{ ...styles.tableCol, width: "100%" }}>
          <Text style={styles.label}>Application name</Text>
          <Text style={styles.tableCell}>{user?.applicationName}</Text>
        </View>
      </View>
      <View style={styles.tableRow}>
        <View style={{ ...styles.tableCol, width: "100%" }}>
          <Text style={styles.label}>Submission date</Text>
          <Text style={styles.tableCell}>
            {moment(submissionDate).format("DD/MM/YYYY HH:mm")}
          </Text>
        </View>
      </View>
    </View>
  );
};

const ClarificationQuestion = ({
  question,
  sectionOrder,
  categoryOrder,
}: {
  question: Question;
  sectionOrder: number | string;
  categoryOrder: number | string;
}) => {
  const clarificationQuestions = question.answers[0].clarificationQuestions.filter(
    (cq) => cq.answerText !== ""
  );
  return clarificationQuestions.length > 0 ? (
    <View style={{ marginLeft: 25, marginBottom: 18 }} wrap={true}>
      <Text
        style={{
          ...styles.h3,
          paddingTop: categoryOrder !== 1 ? 8 : 0,
        }}
        break
      >
        {sectionOrder + "." + categoryOrder + "." + question.order}{" "}
        Clarification questions
      </Text>
      {question.answers[0].clarificationQuestions.map((cq) => (
        <View style={{ ...styles.answer, marginBottom: 8 }} wrap={true}>
          <Text
            style={{
              ...styles.h3,
              marginLeft: 8,
              paddingBottom: 0,
              marginTop: 5,
              width: "97%",
            }}
          >
            {cq.body}
          </Text>
          <Text style={styles.answerTableCell}>{cq.answerText}</Text>
          <Text
            style={{
              ...styles.paragraph,
              marginLeft: 8,
              paddingBottom: 5,
              paddingTop: 5,
            }}
          >
            {moment(cq.datePublished).format("DD/MM/YYYY HH:mm")}
          </Text>
        </View>
      ))}
    </View>
  ) : null;
};

const Answer = ({ question }: { question: Question }) => {
  switch (question.type) {
    case "TEXT":
    case "TEXTAREA":
    case "PHONE":
    case "EMAIL":
    case "CURRENCY":
    case "NUMBER":
      return (
        <View style={styles.answer} wrap={question.type === "TEXTAREA"}>
          <Text style={styles.answerTableCell}>
            {question.type === "CURRENCY" ? "£ " : null}
            {question.type === "CURRENCY"
              ? Number(question.answers[0].value)
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")
              : replaceNewLineCharacter(question.answers[0].value)}
          </Text>
        </View>
      );
    case "RADIO":
      return (
        <View
          style={{ display: "flex", flexDirection: "row", marginBottom: 18 }}
        >
          <Image
            src={"/radio.png"}
            style={{ height: 12, width: 12, marginRight: 8 }}
          />
          <Text style={{ fontSize: 10 }}>{question.answers[0].value}</Text>
        </View>
      );
    case "CHECKBOX":
      return (
        <View>
          {question.answers[0]?.values?.map((answer, index) => (
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 12,
              }}
              key={index}
            >
              <Image
                src={"/checkbox.png"}
                style={{ height: 12, width: 12, marginRight: 8 }}
              />
              <Text style={{ fontSize: 10 }}>{answer}</Text>
            </View>
          ))}
        </View>
      );
    case "FILE":
      const values: { name: string }[] = question.answers.length
        ? JSON.parse(question.answers[0]?.value)
        : [];
      return (
        <View>
          {values?.map((value, index) => {
            return (
              <View
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: 12,
                }}
              >
                <Image
                  src={"/file.png"}
                  style={{ height: 12, width: 12, marginRight: 8 }}
                />
                <Text style={{ fontSize: 10 }}>{value?.name}</Text>
              </View>
            );
          })}
        </View>
      );
    case "DATE":
    case "CQC_ID":
      return (
        <View
          style={{
            ...styles.date,
            width: question.type === "CQC_ID" ? 78 : 70,
          }}
        >
          <Text break={false} style={{ ...styles.answerTableCell }}>
            {question.type === "DATE"
              ? moment(question.answers[0].value).format("DD/MM/YYYY")
              : question.answers[0].value}
          </Text>
        </View>
      );
    case "SELECT_MULTIPLE":
      return (
        <View style={{ ...styles.answer }}>
          <Text style={{ ...styles.answerTableCell }}>
            {question.answers[0].values
              ? question.answers[0].values?.map(
                  (val) =>
                    `${val}${question.answers[0].values.length > 1 ? `\n` : ""}`
                )
              : question.answers[0].value}
          </Text>
        </View>
      );
    default:
      return null;
  }
};

const replaceNewLineCharacter = (string) => {
  const key = {
    "↵": `\n`,
    "↵ ": `\n`,
    "↵  ": `\n`,
    "↵↵": `\n`,
    "↵↵ ": `\n`,
    "↵↵  ": `\n`,
  };

  if (string) {
    return string
      .replace(/[↵]/g, (char) => key[char] || "")
      .replace(/<li>/gi, "• ")
      .replace(/(<([^>]+)>)/gi, "")
      .replace(/\n\n/g, `\n`);
  } else return "";
};

const Category = ({ category, sectionOrder }) => {
  const tagText =
    category.details && /<a[^>]*>([^<]+)<\/a>/g.exec(category.details);
  const details = category.details.replace(
    /<a[^>]*>([^<]+)<\/a>/g,
    tagText && tagText[1]
  );
  return (
    <View key={category.id}>
      <View wrap={false}>
        <Text
          style={{ ...styles.h3, paddingTop: category.order !== 1 ? 8 : 0 }}
          break
        >
          {sectionOrder + "." + category?.order} {category.title}
        </Text>
        {category.details ? (
          <Text style={styles.paragraph}>{details}</Text>
        ) : null}
      </View>
      {category.questions.map((question) => {
        const questionTitle = replaceNewLineCharacter(question.title);
        if (
          !question.answers.length ||
          (!question.answers[0].value &&
            !question.answers[0].values &&
            !question.answers[0].comment)
        ) {
          return null;
        } else {
          return (
            <View
              key={question.id}
              wrap={
                question.type === "TEXTAREA" ||
                question.answers[0].clarificationQuestions.length > 0
              }
            >
              <View wrap={false}>
                <Text style={styles.h3}>
                  {sectionOrder + "." + category?.order + "." + question.order}{" "}
                  {questionTitle}
                </Text>
                {question.details ? (
                  <Text style={styles.paragraph}>
                    {replaceNewLineCharacter(question.details)}
                  </Text>
                ) : null}
              </View>
              <Answer question={question} />
              {question.answers[0].comment ? (
                <View wrap={false}>
                  <Text style={{ ...styles.paragraph, paddingBottom: 2 }}>
                    Comments:
                  </Text>
                  <View style={{ ...styles.answer }} wrap={true}>
                    <Text style={{ ...styles.answerTableCell, marginTop: 5 }}>
                      {question.answers[0].comment}
                    </Text>
                  </View>
                </View>
              ) : null}
              <ClarificationQuestion
                question={question}
                sectionOrder={sectionOrder}
                categoryOrder={category?.order}
              />
            </View>
          );
        }
      })}
    </View>
  );
};

function Doc({ state }) {
  return (
    <Document title="Dom Care AQP e-procurement: Submission copy">
      <Page style={styles.page} size="A4" wrap>
        <Header />
        <Footer />

        <View>
          <Text style={styles.h1}>
            Dom Care AQP e-procurement: Submission copy -{" "}
            {state?.user?.applicationName}
          </Text>
        </View>

        <Text style={{ ...styles.h2, paddingBottom: 6, paddingTop: 0 }}>
          Registration details
        </Text>
        <RegistrationDetailsTable
          user={state?.user}
          submissionDate={state?.dateCompleted}
        />

        {state?.sections?.map((section) => (
          <View key={section.id}>
            <Text
              style={{
                ...styles.h2,
                paddingTop: section?.order !== 1 ? 12 : 0,
              }}
              // break={section?.order !== 1}
            >
              {section?.order} {section?.title}
            </Text>
            {section?.categories?.map((category) => {
              return (
                <Category
                  sectionOrder={section?.order}
                  category={category}
                  key={category?.id}
                />
              );
            })}
          </View>
        ))}
      </Page>
    </Document>
  );
}

export default function PDFExport(props) {
  const [loading, setLoading] = React.useState(false);
  const { clarificationQuestionsSubmittedDate } = useDeadline();
  function saveAs() {
    setLoading(true);
    const instance = pdf(<Doc state={props.state} />);
    instance
      .toBlob()
      .then((blob) => {
        FileSaver.saveAs(
          URL.createObjectURL(blob),
          `Dom Care AQP e-procurement: Submission copy - ${props?.state?.user?.applicationName}`
        );
        setLoading(false);
      })
      .catch((err) => {
        message.error("Questionnaire download failed.");
        setLoading(false);
      });
  }

  return (
    <Tooltip
      title={
        clarificationQuestionsSubmittedDate
          ? `Updated with clarification responses on ${clarificationQuestionsSubmittedDate.format(
              "DD/MM/YYYY"
            )}`
          : null
      }
    >
      <Button
        className="mt-10"
        type="primary"
        size="large"
        loading={loading || props.loading}
        onClick={() => saveAs()}
      >
        Download submitted application
      </Button>
    </Tooltip>
  );
}
