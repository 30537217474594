import * as React from "react";
import { Steps, Spin, Button } from "antd";
import { useRouter } from "next/router";
import { useSectionList } from "hooks/user-section-list";
import SubmitApplication from "./submit-application";
import { useEvaluationContext } from "context/evaluation-context";
import Link from "next/link";
import moment from "moment";
import { useDeadline } from "context/deadline-context";
import { SectionListQuery } from "generated/graphql";

function ComplianceCheckNavigation({
  questionnaire,
}: {
  questionnaire: SectionListQuery["questionnaire"];
}) {
  const router = useRouter();
  const current = Number(router.query.section) - 1;
  return (
    <>
      <Steps
        direction="vertical"
        current={current}
        onChange={(current) => {
          let next = current + 1;
          if (next === 5) {
            next = 6;
          }
          router.push(
            `/compliance-check/[uuid]/section/[section]`,
            `/compliance-check/${questionnaire?.uuid}/section/${next}`
          );
        }}
      >
        {questionnaire?.sections?.map((section, index) => {
          return (
            <Steps.Step
              key={section.order}
              title={section?.title}
              //@ts-ignore
              status={current === index ? "process" : section?.complianceStatus}
              className={
                index !== 4
                  ? current !== index
                    ? (section.complianceStatus === "wait" &&
                        "black-icon black-text") ||
                      (section.complianceStatus === "finish" && "black-text")
                    : (section.complianceStatus === "wait" && "black-text") ||
                      (section.complianceStatus === "finish" && "black-text")
                  : null
              }
              disabled={section.order === 5}
            />
          );
        })}
      </Steps>

      <div className="flex justify-end">
        <Button className="mt-10" type="primary" size="large">
          <Link href="/">
            <a>Home</a>
          </Link>
        </Button>
      </div>
    </>
  );
}

function ClarificationQuestionsNavigation({
  questionnaire,
}: {
  questionnaire: SectionListQuery["questionnaire"];
}) {
  const router = useRouter();
  const current = Number(router.query.section) - 1;
  return (
    <>
      <Steps
        direction="vertical"
        current={current}
        onChange={(current) =>
          router.push(
            `/questionnaire/[uuid]/section/[section]`,
            `/questionnaire/${questionnaire?.uuid}/section/${current + 1}`
          )
        }
      >
        {questionnaire?.sections?.map((section, index) => (
          <Steps.Step
            key={section.order}
            title={section.title}
            description={section.cqDescription}
            //@ts-ignore
            status={current === index ? "process" : section.cqStatus || "wait"}
            disabled={!section.cqStatus}
            className={
              current !== index
                ? (section.cqStatus === "wait" && "black-icon black-text") ||
                  (section.cqStatus === "finish" && "black-text")
                : (section.cqStatus === "wait" && "black-text") ||
                  (section.cqStatus === "finish" && "black-text")
            }
          />
        ))}
      </Steps>

      <div className="flex justify-end">
        <SubmitApplication />
      </div>
    </>
  );
}

function ProviderNavigation({
  questionnaire,
}: {
  questionnaire: SectionListQuery["questionnaire"];
}) {
  const { clarificationQuestionsSubmittedDate } = useDeadline();

  const router = useRouter();
  const current = Number(router.query.section) - 1;

  return (
    <>
      <Steps
        direction="vertical"
        current={current}
        onChange={(current) =>
          router.push(
            `/questionnaire/[uuid]/section/[section]`,
            `/questionnaire/${questionnaire?.uuid}/section/${current + 1}`
          )
        }
      >
        {questionnaire?.sections?.map((section, index) => {
          const status = clarificationQuestionsSubmittedDate
            ? section?.cqStatus
            : section?.status;
          const description = clarificationQuestionsSubmittedDate
            ? section?.cqDescription
            : section?.description;

          return (
            <Steps.Step
              key={section.order}
              title={section?.title}
              description={description}
              //@ts-ignore
              status={current === index ? "process" : status || "wait"}
            />
          );
        })}
      </Steps>

      <div className="flex justify-end">
        <SubmitApplication />
      </div>
    </>
  );
}

const QuestionnaireNavigation = () => {
  const context = useEvaluationContext();
  const { data, loading } = useSectionList();
  const { clarificationQuestionsPending } = useDeadline();
  if (loading) {
    return <Spin className="flex justify-center items-center" size="large" />;
  }

  if (context === "compliance-check") {
    return <ComplianceCheckNavigation questionnaire={data?.questionnaire} />;
  }

  if (clarificationQuestionsPending) {
    return (
      <ClarificationQuestionsNavigation questionnaire={data?.questionnaire} />
    );
  }

  return <ProviderNavigation questionnaire={data?.questionnaire} />;
};

export default QuestionnaireNavigation;
