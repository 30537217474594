import { Button, message } from "antd";
import React from "react";
import client from "utils/client";

export default function DownloadResponses() {
  const [loading, setLoading] = React.useState(false);

  function download() {
    setLoading(true);
    client("/export_responses/")
      .then((data) => {
        window.open(data?.url);
        setLoading(false);
      })
      .catch((err) => {
        message.error("Export could not be generated");
        setLoading(false);
      });
  }

  return (
    <Button onClick={download} loading={loading} className="my-4 self-end">
      Download
    </Button>
  );
}
