import * as React from "react";
import { useState } from "react";
import { FileTextFilled } from "@ant-design/icons/lib";
import { downloadSignedContract } from "./contract-generate";

export const DownloadContract: React.FC<{
  name: string;
  description: string;
  user: any;
}> = ({ name, description, user }) => {
  const [hovered, setHovered] = useState(false);
  const [focused, setFocused] = useState(false);

  const contractURL = `https://${
    process.env.NEXT_PUBLIC_EP_AWS_STORAGE_BUCKET_NAME
  }.s3.eu-west-2.amazonaws.com/eprocurement/contracts/signed/${user.userProfile.procurementuserprofile.legalEntity2021.name
    .trim()
    .replace(/[/\\|:*"<>]/g, "")
    .replace(/ /g, "+")}.pdf`;

  return (
    <div
      role="button"
      className="w-full h-full transition ease-out duration-200 bg-white hover:bg-gray-100 focus:bg-gray-100 rounded border border-solid border-gray p-4 flex cursor-pointer outline-none"
      onFocus={() => {
        setFocused(true);
      }}
      onBlur={() => {
        setFocused(false);
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => {
        setHovered(false);
      }}
      tabIndex={0}
      onClick={() => {
        downloadSignedContract(
          contractURL,
          user?.userProfile?.procurementuserprofile?.legalEntity2021?.name,
          user?.userProfile?.procurementuserprofile?.qualificationquestionnaire
            ?.signatureRequestId
        );
      }}
    >
      <div className="relative flex w-full">
        <div>
          <FileTextFilled className="fill-current mt-1 mr-2 text-blue-2" />
        </div>
        <div className="w-full">
          <div className="flex justify-between w-full">
            <div
              className={`document-name font-semibold text-base text-blue-2 ${
                hovered || focused ? "underline" : ""
              }`}
            >
              {name}
            </div>
          </div>
          <div className="text-gray">{description}</div>
        </div>
      </div>
    </div>
  );
};
