import * as React from "react";
import { Button, List, Spin } from "antd";
import Link from "next/link";
import DeadlineAlert from "components/deadline-alert";
import QuestionnaireNavigation from "components/questionnaire-navigation";
import { Message } from "../components/message";
import { useThreadsQuery } from "generated/graphql";
import { useUser } from "hooks/useAuth";
import { useUnreadMessagesCount } from "pages/messages";
import OutcomeLetter from "./outcome-letter";

export default function ProviderHomepage() {
  const { loading, data } = useThreadsQuery();
  const count = useUnreadMessagesCount();
  const { user } = useUser({
    redirectTo: "/login",
  });

  return (
    <div>
      <h1 className="text-4xl font-bold text-blue-title">Home</h1>
      {/* <Link
        href={`mobilisation-form/${mobilisationForm?.data?.mobilisation2021Initial?.id}`}
      >
        <a>Mobilisation Form</a>
      </Link> */}
      <div
        className="flex pt-0 flex-col xl:flex-row pb-6"
        style={{ position: "relative" }}
      >
        <div className="flex flex-col flex-wrap w-full border-gray">
          <div className="flex w-full bg-white rounded p-6 flex-wrap mb-6">
            <h2 className="w-full">Documents</h2>
            <p className="w-full flex justify-between items-center">
              Visit documents page to see all the documents available
              <Link href="/documents">
                <Button>See all documents</Button>
              </Link>
            </p>
          </div>
          {user?.userProfile?.provideruserprofile?.domcareProcurement
            ?.qualificationquestionnaire?.letterIssued &&
          user?.userProfile?.provideruserprofile?.domcareProcurement
            ?.qualificationquestionnaire?.outcomeLetterStatus ? (
            <div className="w-full flex-wrap">
              <OutcomeLetter user={user} />
            </div>
          ) : (
            <div className="w-full bg-white rounded p-6 flex-wrap">
              <h2 className="w-full">Qualification questionnaire</h2>
              <DeadlineAlert />
              <QuestionnaireNavigation />
            </div>
          )}
        </div>
        <div className="bg-white rounded w-full xl:w-5/12 border borer-solid border-gray flex flex-col relative overflow-hidden mt-6 xl:mt-0 xl:ml-6">
          <div className="bg-white z-10 p-6 shadow rounded rounded-b-none">
            <h2 className="w-full">Message board</h2>
            <div className="flex justify-between items-center">
              <div>{count != null && <span>{count} unread messages</span>}</div>
              <Link href="/messages">
                <Button>See all messages</Button>
              </Link>
            </div>
          </div>
          <div className="relative w-full xl:h-full" style={{ minHeight: 600 }}>
            <div className="overflow-scroll overflow-x-hidden overflow-y-auto absolute right-0 top-0 w-full h-full z-0 rounded rounded-t-none">
              <List
                loading={{
                  spinning: loading,
                  size: "large",
                }}
                itemLayout={"vertical"}
                size={"large"}
                dataSource={data?.threads.edges.slice(0, 10)}
                renderItem={(edge) => <Message item={edge.node} small />}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
