import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  useIssueLettersMutation,
  useLettersIssuedQuery,
  useQuestionnairesExportQuery,
} from "generated/graphql";
import moment from "moment";
import { Button, Select, Alert, message, Modal, Progress } from "antd";
import { LoadingOutlined } from "@ant-design/icons/lib";
import { getToken } from "../utils/client";
import * as _ from "lodash";
import { createApolloFetch } from "apollo-fetch";

const apolloFetch = createApolloFetch({
  uri: process.env.NEXT_PUBLIC_API_URL + "/graphiql/",
});

apolloFetch.use(({ request, options }, next) => {
  if (!options.headers) {
    options.headers = {};
  }
  options.headers["authorization"] = getToken();

  next();
});

const OutcomeLetters: React.FC = () => {
  const [mutate] = useIssueLettersMutation();
  const { data, refetch } = useLettersIssuedQuery();
  const [outcome, setOutcome] = useState(null);
  const [issued, setIssued] = useState(false);
  const [toFetch, setToFetch] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (data) {
      setIssued(data?.lettersIssuedInfo?.lettersIssued);
    }
  }, [data?.lettersIssuedInfo?.lettersIssued]);

  function showConfirm() {
    Modal.confirm({
      width: 600,
      title: "Are you sure you want to send all the outcome letters out now?",
      content: (
        <>
          <div>Make sure all types of outcome letters have been generated.</div>
          <div>
            After confirmation, all the generated outcome letters will be
            displayed on the providers' e-Procurement homepages.
          </div>
        </>
      ),
      onOk() {
        fetch(
          `${process.env.NEXT_PUBLIC_API_URL}/api/send_outcome_letter_emails/`,
          { method: "POST", headers: { Authorization: getToken() } }
        )
          .then(() => {
            mutate()
              .then((res) => {
                if (res.data.issueLetters.issued === true) {
                  message.success("Outcome letters sent successfully");
                  refetch()
                    .then((res) => console.log(res))
                    .catch((err) => console.log(err));
                }
              })
              .catch((err) => {
                console.log(err);
                message.error({
                  message: `There was an error while sending outcome letters`,
                });
              });
          })
          .catch(() => {
            message.error(
              "There was an error while sending outcome letter message emails."
            );
          });
      },
      onCancel() {},
    });
  }

  return issued ? (
    <>
      <h4>Outcome letters</h4>
      <Alert
        type={"info"}
        message={
          <div>
            Letters have been issued on{" "}
            <strong>
              {moment(data?.lettersIssuedInfo?.lettersIssuedDate).format(
                "DD/MM/YYYY HH:mm"
              )}
            </strong>
          </div>
        }
        showIcon
      />
    </>
  ) : (
    <>
      <h4>Outcome letters</h4>
      <div className="flex">
        <Select
          style={{ minWidth: 150 }}
          className="mr-4"
          disabled={loading || issued}
          value={outcome}
          onChange={(val) => setOutcome(val)}
          placeholder={"Outcome"}
        >
          <Select.Option value="pass">Pass</Select.Option>
          <Select.Option value="pass-pcg">Pass with PCG</Select.Option>
          <Select.Option value="fail">Fail</Select.Option>
          <Select.Option value="withdrawn">Withdrawn</Select.Option>
        </Select>
        <Button
          className="mr-4"
          disabled={loading || !outcome || issued}
          onClick={() => {
            setToFetch(true);
          }}
        >
          Generate
        </Button>
        {toFetch && outcome && (
          <FetchAndGenerate
            outcome={outcome}
            setToFetch={setToFetch}
            setOutcome={setOutcome}
            setLoading={setLoading}
          />
        )}
        <Button
          type={"primary"}
          disabled={loading || issued}
          onClick={showConfirm}
        >
          Send
        </Button>
      </div>
    </>
  );
};

const FetchAndGenerate: React.FC<{
  outcome: string;
  setToFetch: any;
  setOutcome: any;
  setLoading: any;
}> = ({ outcome, setToFetch, setOutcome, setLoading }) => {
  const { data, loading } = useQuestionnairesExportQuery({
    variables: { outcome: outcome },
    fetchPolicy: "no-cache",
  });

  const [totalBatches, setTotalBatches] = useState(0);
  const [doneBatches, setDoneBatches] = useState(0);
  const [showProgress, setShowProgress] = useState(false);

  useEffect(() => {
    if (loading) {
      setLoading(true);
    } else {
      request(outcome);
    }
  }, [loading]);

  useLayoutEffect(() => {
    if (showProgress) {
      return message.open({
        type: "loading",
        content: (
          <span>
            <span>Generating {outcome} outcome letter PDFs</span>
            <br />
            <Progress
              percent={(doneBatches / totalBatches) * 100}
              style={{ width: 300, paddingRight: 4 }}
              format={(percent) => Math.round(percent) + "%"}
            />
          </span>
        ),
        duration: 0,
        key: "generate progress",
      });
    }
  }, [doneBatches, totalBatches, showProgress]);

  const unmount = () => {
    setLoading(false);
    setOutcome(null);
    setToFetch(false);
  };

  const messageKeys = [
    "generate progress",
    `upload ${outcome} loading`,
    `upload ${outcome} success`,
  ];

  const destroyMessages = () => {
    messageKeys.forEach((key) => {
      // @ts-ignore
      message.destroy(key);
    });
  };

  const request = async (outcome) => {
    const outcomeString =
      outcome === "pass-pcg"
        ? "Pass with PCG"
        : outcome.charAt(0).toUpperCase() + outcome.slice(1);

    setShowProgress(true);

    if (!loading) {
      await fetch(`/api/download-lph-logo`, {
        method: "POST",
      })
        .then(async (res) => {
          if (res.status === 200) {
            const chunks = _.chunk(data?.questionnairesExport, 10);
            setTotalBatches(chunks.length);
            for (const chunk of chunks) {
              await apolloFetch({
                query: `query QuestionnairesExportBatch($outcome: String, $group: [String]) {
                  questionnairesExport(group: $group, outcome: $outcome) {
                    id
                    uuid
                    lastSave
                    dateCompleted
                    created
                    status
                    ccStatus
                    feStatus
                    teStatus
                    outcomeLetterStatus
                    overallStatus
                    answers {
                      id
                      moderationOutcome
                      moderationComment
                      value
                      clarificationQuestions {
                        id
                        body
                        datePublished
                        dateSubmitted
                      }
                      question {
                        id
                        order
                        title
                        details
                        category {
                          id
                          order
                          title
                          details
                          section {
                            id
                            order
                            title
                            description
                          }
                        }
                      }
                    }
                    user {
                      id
                      applicationName
                      legalEntity2021 {
                        uuid
                        providerdomcareSet {
                          contractName
                        }
                      }
                      user {
                        fullName
                        email
                      }
                    }
                  }
                }
                `,
                variables: {
                  group: chunk.map((x) => x.uuid),
                  outcome,
                },
              }).then((res) => {
                return fetch(`/api/generate-outcome-letters`, {
                  method: "POST",
                  body: JSON.stringify({
                    questionnaires: res?.data?.questionnairesExport || [],
                    outcome: outcome,
                  }),
                })
                  .then((res) => {
                    if (res.status === 200) {
                      setDoneBatches((prevState) => prevState + 1);
                    }
                  })
                  .catch((e) => {
                    destroyMessages();
                    message.error({
                      content: `There was an error generating ${outcome.toLowerCase()} outcome letters.`,
                      key: `generate ${outcome} error`,
                      onClose: () => unmount(),
                    });
                  });
              });
            }
            setShowProgress(false);
            // @ts-ignore
            message.destroy("generate progress");
            message.open({
              type: "loading",
              icon: <LoadingOutlined />,
              content: "Uploading",
              duration: 0,
              key: `upload ${outcome} loading`,
            });
            await fetch(`/api/upload-outcome-letters`, {
              method: "POST",
              body: JSON.stringify({
                outcome: outcome,
              }),
            })
              .then((res) => {
                if (res.status === 200) {
                  // @ts-ignore
                  message.destroy(`upload ${outcome} loading`);
                  message.success({
                    content: (
                      <span>
                        <span>
                          {outcomeString} outcome letters successfully uploaded
                          to S3.
                        </span>
                        <br />
                        <a
                          href={`https://s3.console.aws.amazon.com/s3/buckets/${
                            process.env.NEXT_PUBLIC_EP_AWS_STORAGE_BUCKET_NAME
                          }/eprocurement/outcome-letters/${outcome.toLowerCase()}-outcome-letters/`}
                          target="_blank"
                        >
                          Click here to view the {outcomeString} letters
                        </a>
                        <br />
                        <Button
                          onClick={() =>
                            // @ts-ignore
                            message.destroy(`upload ${outcome} success`)
                          }
                          size={"small"}
                          style={{ marginTop: 8 }}
                        >
                          Dismiss
                        </Button>
                      </span>
                    ),
                    duration: 20,
                    key: `upload ${outcome} success`,
                  });
                  unmount();
                }
              })
              .catch((e) => {
                destroyMessages();
                message.error({
                  content: `There was an error uploading ${
                    outcome === "pass-pcg" ? "pass with PCG" : outcome
                  } outcome letters to S3.`,
                  key: `upload ${outcome} error`,
                  onClose: () => unmount(),
                });
              });
          }
        })
        .catch(() => {
          destroyMessages();
          message.error({
            content: `There was an error generating ${outcome.toLowerCase()} outcome letters.`,
            key: `download logo error`,
            onClose: () => unmount(),
          });
        });
    }
  };
  return <div />;
};

export default OutcomeLetters;
