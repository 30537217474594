import React, { useEffect, useRef, useState } from "react";
import { Button, Table, Input, Select, Statistic } from "antd";
import {
  useMobilisationTableQuery,
  useSignContractMutation,
  useSignContractsMutation,
} from "generated/graphql";
import OutcomeLetter from "components/outcome-letter-select";
import GenerateOutcomeLetters from "components/outcome-letter-generate";
import {
  CommissionerContractAndEmails,
  ContractPDFColumn,
  ContractReturnsColumn,
} from "./contract-generate";

function useInterval(callback, delay) {
  const savedCallback = useRef();
  // Remember the latest callback
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval
  useEffect(() => {
    function tick() {
      // @ts-ignore
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => {
        clearInterval(id);
      };
    }
  }, [callback, delay]);
}

export default function EvaluationTable() {
  const [signatureRequests, setSignatureRequests] = useState([]);
  const [signContracts] = useSignContractsMutation();
  useInterval(async () => {
    fetch(`/api/poll-hellosign`, { method: "POST" })
      .then((res) => res.json())
      .then((res) => {
        setSignatureRequests(res.signatureRequests);
        const signatureRequestIds =
          signatureRequests
            ?.filter((request) => request.is_complete)
            .map((request) => request.signature_request_id) || [];
        signContracts({
          variables: {
            signatureRequestIds,
          },
        });
      });
  }, 20000);

  const [variables, setVariables] = React.useState({
    first: 10,
    submitted: true,
  });
  const {
    data,
    networkStatus,
    fetchMore,
    ...options
  } = useMobilisationTableQuery({
    variables,
    notifyOnNetworkStatusChange: true,
  });

  React.useEffect(() => {
    options.refetch(variables);
  }, [variables]);

  return (
    <div className="flex flex-col">
      <GenerateOutcomeLetters />
      <CommissionerContractAndEmails />
      <div style={{ height: 15 }} />
      <Input.Search
        //@ts-ignore
        onSearch={(name) => setVariables({ ...variables, name })}
        loading={networkStatus < 7}
        className="mb-4 w-64"
      />
      <Table
        className="mt-4"
        loading={networkStatus < 7}
        columns={[
          {
            title: "Application name",
            render: ({ node }) => node.user.applicationName,
          },
          {
            title: () => (
              <div>
                <div>Outcome letters</div>
                <Select
                  className="w-full"
                  onChange={(outcome) => {
                    //@ts-ignore
                    setVariables({ ...variables, outcome });
                  }}
                >
                  <Select.Option value="pass">Pass</Select.Option>
                  <Select.Option value="fail">Fail</Select.Option>
                  <Select.Option value="pass-pcg">Pass PCG</Select.Option>
                  <Select.Option value="withdrawn">Withdrawn</Select.Option>
                </Select>
              </div>
            ),
            render: ({ node }) => (
              <OutcomeLetter
                default={node.outcomeLetterStatus}
                uuid={node.uuid}
              />
            ),
          },
          {
            title: () => (
              <div>
                <div>Mobilisation form status</div>
                <Select
                  className="w-full"
                  onChange={(mobilisationStatus) => {
                    //@ts-ignore
                    setVariables({ ...variables, mobilisationStatus });
                  }}
                >
                  <Select.Option value="Incomplete">Incomplete</Select.Option>
                  <Select.Option value="Submitted">Submitted</Select.Option>
                </Select>
              </div>
            ),
            render: ({ node }) => {
              return (
                <div>
                  {node.outcomeLetterStatus === "fail" ||
                  node.outcomeLetterStatus === "withdrawn" ? (
                    "N/A"
                  ) : node.mobilisationStatus === "Submitted" ? (
                    <a
                      target="_blank"
                      href={`/mobilisation-form/${node.user.legalEntity2021.uuid}`}
                    >
                      {node.mobilisationStatus}
                    </a>
                  ) : (
                    node.mobilisationStatus || "Incomplete"
                  )}
                </div>
              );
            },
          },
          {
            title: () => (
              <div>
                <div>Contract returns</div>
                <Select
                  className="w-full"
                  onChange={(contractSigned) => {
                    //@ts-ignore
                    setVariables({ ...variables, contractSigned });
                  }}
                >
                  <Select.Option value="signed">Signed</Select.Option>
                  <Select.Option value="unsigned">Unsigned</Select.Option>
                </Select>
              </div>
            ),
            render: ({ node }) => (
              <ContractReturnsColumn
                node={node}
                signatureRequest={
                  signatureRequests?.filter(
                    (request) =>
                      request.signature_request_id === node.signatureRequestId
                  )[0]
                }
              />
            ),
          },
          {
            title: "Contract PDF",
            render: ({ node }) => {
              return <ContractPDFColumn node={node} />;
            },
          },
        ]}
        rowKey={({ node }) => node.uuid}
        dataSource={data?.questionnaires?.edges}
        pagination={false}
      />
      <Statistic
        className="self-end"
        value={data?.questionnaires?.edges?.length}
        suffix={`/ ${data?.questionnaires?.totalCount || 0}`}
      />
      <Button
        className="self-center mt-2"
        disabled={!data?.questionnaires?.pageInfo?.hasNextPage}
        onClick={() => {
          fetchMore({
            variables: {
              after: data?.questionnaires?.pageInfo?.endCursor,
            },
          });
        }}
      >
        Load more
      </Button>
    </div>
  );
}
