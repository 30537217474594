import * as React from "react";
import { Button, Tag } from "antd";
import { FlagFilled } from "@ant-design/icons/lib";
import { useUser } from "../hooks/useAuth";
import { DownloadContract } from "./download-contract-card";

export const ContractDownload = ({ contractPage = false }) => {
  const { user } = useUser({
    redirectTo: "/login",
  });
  return contractPage ? (
    <div>
      <h1 className="text-4xl font-bold text-blue-title">Contract</h1>
      <div className="md:w-full lg:w-1/2">
        <div className="flex w-full bg-white rounded p-6 flex-wrap">
          <p>
            You have successfully signed your contract. You can download it
            below.
          </p>
          <DownloadContract
            name={`Pan-London Domiciliary Care AQP 2020 Contract - ${user.userProfile.provideruserprofile?.domcareProcurement?.legalEntity2021.name}`}
            description={
              "Download a copy of your Pan-London Domiciliary Care AQP 2020 signed contract."
            }
            user={user}
          />
        </div>
      </div>
    </div>
  ) : (
    <div className="mb-6 w-full">
      <div className="w-full">
        <h2 className="w-full">
          Contract{" "}
          <Tag
            color={"#A55BEA"}
            style={{
              borderRadius: 12,
              marginLeft: 12,
              marginTop: 4,
              fontSize: 14,
              lineHeight: "22px",
            }}
          >
            New <FlagFilled />
          </Tag>
        </h2>
        <p>
          You have successfully signed your contract. You can download it below.
        </p>
        <DownloadContract
          name={`Pan-London Domiciliary Care AQP 2020 Contract - ${user.userProfile.provideruserprofile.domcareProcurement.legalEntity2021.name}`}
          description={
            "Download a copy of your Pan-London Domiciliary Care AQP 2020 signed contract."
          }
          user={user}
        />
      </div>
    </div>
  );
};

export const ContractAvailable = () => {
  const { user } = useUser({});
  return (
    <div className="mb-6 w-full">
      <div className="w-full">
        <h2 className="w-full">
          Contract ready to be signed{" "}
          <Tag
            color={"#A55BEA"}
            style={{
              borderRadius: 12,
              marginLeft: 12,
              marginTop: 4,
              fontSize: 14,
              lineHeight: "22px",
            }}
          >
            New <FlagFilled />
          </Tag>
        </h2>
        <p>
          We have issued your contract and it is now available for you sign.
          Please check your inbox.
        </p>
        {/*<Button*/}
        {/*  href={`/contract/${user?.userProfile?.procurementuserprofile?.legalEntity2021.uuid}`}*/}
        {/*>*/}
        {/*  Sign contract now*/}
        {/*</Button>*/}
      </div>
    </div>
  );
};
