import { useSectionsExportQuery } from "../generated/graphql";
import { useRouter } from "next/router";
import { useUser } from "./useAuth";

export function useSectionsExport(options?: {}) {
  const router = useRouter();
  const { user } = useUser({});
  const uuid =
    (router.query.uuid as string) ||
    user?.userProfile?.provideruserprofile?.domcareProcurement
      ?.qualificationquestionnaire?.uuid;
  return useSectionsExportQuery({
    ...options,
    variables: {
      uuid,
    },
    skip: !uuid,
  });
}
