import React from "react";

type PageType =
  | "provider"
  | "technical-evaluation"
  | "compliance-check"
  | "moderation-meeting"
  | "mobilisation";

const initialState: PageType = "provider";

const EvaluationContext = React.createContext<PageType>(initialState);

export const EvaluationContextProvider: React.FC<{ value: PageType }> = ({
  children,
  value,
}) => (
  <EvaluationContext.Provider value={value}>
    {children}
  </EvaluationContext.Provider>
);

export const useEvaluationContext = () => {
  return React.useContext(EvaluationContext);
};
