import { Spin, Statistic, Table } from "antd";
import { useEvaluationStatisticsQuery } from "generated/graphql";

export default function EvaluationStatistics() {
  const { data, loading } = useEvaluationStatisticsQuery();
  const total = data?.evaluationStatistics?.reduce(
    (acc, stat) => acc + stat.value,
    0
  );
  return (
    <Spin spinning={loading} size="large">
      <div className="flex flex-wrap mb-4">
        {data?.evaluationStatistics?.map((stat) => (
          <Statistic
            key={stat.title}
            title={stat.title}
            value={stat.value}
            suffix={`/ ${total > 0 ? total / 4 : 0}`}
            className="w-1/4 mb-3"
          />
        ))}
      </div>
    </Spin>
  );
}
