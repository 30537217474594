import React, { useState } from "react";
import ColorSelect from "components/color-select";
import { useSetOutcomeLetterStatusMutation } from "generated/graphql";

const selectOptions = [
  { value: "pass", display: "Pass", color: "#85BB5A" },
  { value: "fail", display: "Fail", color: "#AED191" },
  { value: "pass-pcg", display: "Pass PCG", color: "#F78859" },
  { value: "withdrawn", display: "Withdrawn", color: "" },
];

interface Props {
  // The default value of the color select.
  default: string;
  uuid: string;
}

const OutcomeLetter: React.FC<Props> = (props) => {
  const [value, setValue] = useState(props.default || null);
  const [mutate] = useSetOutcomeLetterStatusMutation();

  return (
    <ColorSelect
      size="large"
      width={180}
      title="Outcome letter"
      onChange={(option) => {
        setValue(option);
        mutate({
          variables: {
            uuid: props.uuid,
            status: option,
          },
        })
          .then((q) => {
            console.log(q.data.setOutcomeLetterStatus.questionnaire);
          })
          .catch((err) => console.log(err));
      }}
      options={selectOptions}
      value={value}
    />
  );
};

export default OutcomeLetter;
