import { useMemo, useState } from "react";
import { Button, Progress, List, Alert, Select } from "antd";
import Link from "next/link";
import { FileTextTwoTone } from "@ant-design/icons";
import { useEvaluatorTeamQuery } from "generated/graphql";

const statusMap = {
  "in-progress": "In Progress",
  "ready-to-evaluate": "Ready to Evaluate",
  "ready-for-moderation": "Ready to Moderate",
  "cqs-to-be-published": "CQs to be published",
  published: "Awaiting clarification response",
};

export default function EvaluatorHomepage({
  userProfile,
}: {
  userProfile: any;
}) {
  const dataDoc = [
    {
      title: "Pan-London Domiciliary Care AQP 2020 – Introductory Session V2",
      link:
        "https://carepulse-static.s3-eu-west-1.amazonaws.com/AQP+documents/Pan-London+Domiciliary+Care+AQP+2020+%E2%80%93+Introductory+Session+V2%5B508%5D.pdf",
    },
    {
      title: "Pan-London Domiciliary Care AQP 2020 – Evaluation Guidance",
      link:
        "https://carepulse-static.s3-eu-west-1.amazonaws.com/AQP+documents/Pan-London+Domiciliary+Care+AQP+2020+%E2%80%93+Evaluation+Guidance%5B507%5D.pdf",
    },
    {
      title: "Conflict of Interest and Confidentiality Statement",
      link:
        "https://carepulse-static.s3-eu-west-1.amazonaws.com/AQP+documents/Conflict+of+Interest+and+Confidentiality+Statement.docx",
    },
    {
      title: "Technical Evaluation FAQ",
      link:
        "https://carepulse-static.s3-eu-west-1.amazonaws.com/AQP+documents/Technical+Evaluation+FAQ%5B509%5D.pdf",
    },
  ];

  const [sort, setSort] = useState("");

  const { data, loading } = useEvaluatorTeamQuery({
    variables: {
      username: userProfile.username,
    },
  });
  let applicationData = data?.team.applications;

  const alertStatus = useMemo(() => {
    let RTE = false;
    let inProgress = false;
    let RTM = false;
    data?.team?.applications?.forEach((application) => {
      const questionnaire = application?.qualificationquestionnaire;
      if (!inProgress) {
        if (questionnaire.evaluatorStatus === "in-progress") {
          inProgress = true;
        } else if (
          questionnaire.evaluatorStatus === "ready-to-evaluate" &&
          RTM
        ) {
          inProgress = true;
        } else if (questionnaire.evaluatorStatus === "ready-to-evaluate") {
          RTE = true;
        } else {
          RTM = true;
        }
      }
    });

    if (inProgress) {
      return "In progress";
    } else if (RTE) {
      return "Ready to evaluate";
    } else if (RTM) {
      return "Ready for Moderation";
    }
  }, [data]);

  return (
    <div>
      <h1 className="text-4xl font-bold text-blue-title">
        Technical Evaluation
      </h1>
      <div
        className="flex pt-0 flex-col xl:flex-row pb-6"
        style={{ position: "relative" }}
      >
        <div className="flex flex-col flex-wrap w-full border-gray">
          <div className="flex w-full bg-white rounded p-6 flex-wrap mb-6">
            {alertStatus === "Ready for Moderation" ? (
              <Alert
                className="w-full"
                message={
                  <>
                    <p className="mb-0">
                      Overall status:{" "}
                      <span className="font-bold">{alertStatus}</span>
                    </p>
                  </>
                }
                banner={true}
                type="success"
                showIcon
              />
            ) : (
              <Alert
                className="w-full"
                message={
                  <>
                    <p className="mb-0">
                      Overall status:{" "}
                      <span className="font-bold">{alertStatus}</span>
                    </p>
                  </>
                }
                banner={true}
                type="info"
                showIcon
              />
            )}
            {/* <Select
              className="mt-7 h-8"
              style={{ width: 120 }}
              placeholder="Sort by"
              onChange={(value) => setSort(`${value}`)}
            >
              <Select.Option value="alphabetical">Alphabetical</Select.Option>
              <Select.Option value="status">Status</Select.Option>
            </Select> */}
            <List
              className="w-full mt-6"
              // split={false}
              itemLayout="horizontal"
              dataSource={applicationData}
              loading={loading}
              renderItem={(item) => {
                const [value, total] = item?.qualificationquestionnaire
                  ?.technicalClarificationProgress || [0, 0];

                return (
                  <List.Item>
                    <List.Item.Meta
                      title={
                        <div>
                          <Link
                            href="/technical-evaluation/[uuid]/"
                            as={`/technical-evaluation/${item?.qualificationquestionnaire.uuid}/`}
                          >
                            <a>
                              {item?.applicationName != ""
                                ? item?.applicationName
                                : "Application Name Here"}
                            </a>
                          </Link>
                          <span className="absolute right-0">
                            {
                              statusMap[
                                item.qualificationquestionnaire.evaluatorStatus
                              ]
                            }
                          </span>
                          {["cqs-to-be-published", "published"].indexOf(
                            item.qualificationquestionnaire.evaluatorStatus
                          ) > -1 ? (
                            <Progress
                              className="mt-3"
                              strokeColor="lightgray"
                              showInfo={false}
                              percent={100}
                            />
                          ) : total > 0 ? (
                            <div className="flex items-end justify-between">
                              <Progress
                                className="mt-3"
                                percent={100 * (value / total)}
                                showInfo={false}
                              />
                              <span className="whitespace-no-wrap ml-2">
                                {value} / {total}
                              </span>
                            </div>
                          ) : (
                            <Progress
                              className="mt-3"
                              percent={
                                item?.qualificationquestionnaire
                                  ?.technicalEvaluatorProgress
                              }
                            />
                          )}
                        </div>
                      }
                    />
                  </List.Item>
                );
              }}
            />
            {/* Application Loop will go here */}
            {/* Code to iterate through users assigned evaluations */}
          </div>
        </div>
        <div className="rounded w-full xl:w-5/12 border borer-solid border-gray flex flex-col relative overflow-hidden xl:mt-0 xl:ml-6">
          <div className="bg-white z-10 p-6 rounded-b-none">
            <List
              itemLayout="horizontal"
              header={<h4 className="mb-0 mt-0">Documents</h4>}
              dataSource={dataDoc}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={<FileTextTwoTone />}
                    title={
                      <a
                        target="_blank"
                        style={{ color: "#1477D3" }}
                        href={item.link}
                      >
                        {item.title}
                      </a>
                    }
                    // description={item.descrip}
                  />
                </List.Item>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
