import React from "react";
import { Button, Tooltip, Modal, message } from "antd";
import { useRouter } from "next/router";
import {
  useSubmitApplicationMutation,
  useSubmitClarificationQuestionsMutation,
} from "generated/graphql";
import { useSectionList } from "hooks/user-section-list";
import { useSectionsExport } from "../hooks/useSectionsExport";
import PDFExport from "./pdfExport";
import { useDeadline } from "context/deadline-context";
import moment from "moment";
import fromErrors from "utils/from-errors";

export default function SubmitApplication() {
  const { data } = useSectionList();
  const {
    deadlineExpired,
    clarificationQuestionsPending,
    clarificationQuestionsSubmittedDate,
  } = useDeadline();
  const { data: sections, loading: sectionsLoading } = useSectionsExport();
  const [mutate, { loading }] = useSubmitApplicationMutation({
    refetchQueries: ["SectionList", "SectionsExport"],
  });
  const [visible, setVisible] = React.useState(false);
  const router = useRouter();
  const id = data?.questionnaire?.id;
  const disabled =
    deadlineExpired ||
    !data?.questionnaire?.sections?.every(
      (section) => section?.status === "finish"
    );
  const dateCompleted = data?.questionnaire?.dateCompleted;

  function submit() {
    mutate({
      variables: { input: { id } },
    })
      .then(({ data }) => {
        if (data?.submitApplication?.questionnaire?.dateCompleted) {
          setVisible(false);
          router.push("/");
          message.success("Questionnaire submitted");
        } else {
          message.error("Questionnaire could not be submitted");
        }
      })
      .catch(() => {
        message.error("Questionnaire could not be submitted");
      });
  }

  // if (clarificationQuestionsPending) {
  //   return <SubmitClarificationQuestions />;
  // }

  if (dateCompleted) {
    return (
      <PDFExport state={sections?.sectionsExport} loading={sectionsLoading} />
    );
  }

  return (
    <>
      <Modal
        confirmLoading={loading}
        onOk={submit}
        visible={visible}
        onCancel={() => setVisible(false)}
        title={`Confirm submission of qualification questionnaire for ${data?.questionnaire?.user?.applicationName}`}
      >
        Please note you will not be able to edit the qualification questionnaire
        once you submit it
      </Modal>
      <Tooltip
        title={
          deadlineExpired
            ? "Application deadline expired"
            : disabled
            ? "You must complete all sections before you can submit the application"
            : null
        }
      >
        <Button
          disabled={disabled}
          onClick={() => setVisible(true)}
          className="mt-10"
          type="primary"
          size="large"
        >
          Submit application
        </Button>
      </Tooltip>
    </>
  );
}

function SubmitClarificationQuestions() {
  const { data } = useSectionList();
  const {
    clarificationQuestionsSubmittedDate,
    clarificationQuestionsDeadlinePassed,
  } = useDeadline();
  const [mutate, { loading }] = useSubmitClarificationQuestionsMutation({
    refetchQueries: ["SectionList", "SectionsExport"],
  });
  const [visible, setVisible] = React.useState(false);
  const router = useRouter();

  const disabled = !data?.questionnaire?.sections?.every(
    (section) => !section?.cqStatus || section?.cqStatus === "finish"
  );
  function submit() {
    mutate({
      variables: { uuid: data?.questionnaire?.uuid },
    })
      .then(({ data }) => {
        const errors = fromErrors(data?.submitClarificationQuestions?.errors);
        if (errors?.nonFieldErrors) {
          message.error(errors?.nonFieldErrors);
        } else if (
          data?.submitClarificationQuestions?.submittedClarificationQuestions
            ?.length > 0
        ) {
          setVisible(false);
          router.push("/");
          message.success("Clarification response received");
        } else {
          message.error("Clarification response could not be submitted");
        }
      })
      .catch(() => {
        message.error("Clarification response could not be submitted");
      });
  }

  return (
    <>
      <Modal
        confirmLoading={loading}
        onOk={submit}
        visible={visible}
        onCancel={() => setVisible(false)}
        title={
          <div>
            Confirm submission of clarification question responses for:
            <div>{data?.questionnaire?.user?.applicationName}</div>
          </div>
        }
      >
        Please note you will not be able to edit responses once you submit.
      </Modal>
      <Tooltip
        title={
          clarificationQuestionsDeadlinePassed
            ? "The deadline has passed and it is no longer possible to submit a response to these questions."
            : disabled
            ? "You must complete all clarification questions before you can submit."
            : null
        }
      >
        <Button
          className="mt-10"
          type="primary"
          size="large"
          onClick={() => {
            setVisible(true);
          }}
          disabled={
            !!clarificationQuestionsSubmittedDate ||
            disabled ||
            clarificationQuestionsDeadlinePassed
          }
        >
          Submit clarification responses
        </Button>
      </Tooltip>
    </>
  );
}
