/**
 * Used to show a modal to the LPH team before they publish clarifications to applicants.
 */
import * as React from "react";
import { Modal, Collapse, Spin, Empty, message } from "antd";
import {
  usePublishClarificationQuestionsMutation,
  useClarificationListQuery,
} from "generated/graphql";
import fromErrors from "utils/from-errors";

interface Props {
  visible: boolean;
  closeModal: () => void;
  questionnaire: { uuid: string; name: string };
  moderation?: boolean;
}

const Publish: React.FC<Props> = ({
  visible,
  closeModal,
  questionnaire,
  moderation = false,
}) => {
  const clarData = useClarificationListQuery({
    variables: { uuid: questionnaire?.uuid, isModeration: moderation },
    skip: !questionnaire?.uuid || !visible,
  });
  const clarificationQuestions = clarData?.data?.clarificationQuestions;
  const [mutate, { loading }] = usePublishClarificationQuestionsMutation();

  function submit() {
    mutate({
      variables: { ids: clarificationQuestions?.map((q) => q.id) || [] },
      refetchQueries: ["EvaluationTable"],
    }).then((response) => {
      response?.data?.publishClarificationQuestions?.errors?.forEach((x) =>
        x?.messages?.forEach((m) => {
          message.error(m);
        })
      );
    });
  }
  const noData = !loading && !clarificationQuestions?.length;
  return (
    <>
      <Modal
        confirmLoading={loading}
        destroyOnClose
        okText="Publish"
        onOk={() => {
          submit();
          closeModal();
        }}
        visible={visible}
        onCancel={() => {
          closeModal();
        }}
        getContainer="#root"
        title={questionnaire.name}
        okButtonProps={{
          disabled: noData,
        }}
      >
        <Spin spinning={clarData?.loading} className="w-full mt-3 mb-3">
          {noData ? (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          ) : (
            <Collapse>
              {clarificationQuestions?.map((clarificationQuestion) => {
                const question = clarificationQuestion?.answer?.question;
                return (
                  <Collapse.Panel
                    header={
                      <h3 className="p-0 m-0 text-left">
                        {question.category.section.order +
                          "." +
                          question.category.order +
                          "." +
                          question.order}
                      </h3>
                    }
                    key={question.id}
                  >
                    <div className="items-center justify-center p-1 mb-2">
                      {clarificationQuestion.body}
                    </div>
                  </Collapse.Panel>
                );
              })}
            </Collapse>
          )}
        </Spin>

        <div className="mt-1">
          Please note: Applicants will be sent all clarification questions that
          you have written.
        </div>
      </Modal>
    </>
  );
};

export default Publish;
