import React from "react";
import { Select } from "antd";

interface Props {
  options: {
    value: string;
    display: string;
  }[];
  size: "large" | "middle" | "small";
  title: string;
  value: any;
  width?: number;
  onChange: any;
  placeholder?: string;
  disabled?: boolean;
}

const ColorSelect: React.FC<Props> = ({
  value,
  options,
  size,
  onChange,
  placeholder,
  width,
  disabled = false,
}) => {
  return (
    <Select
      size={size}
      className={value}
      onChange={onChange}
      value={value}
      dropdownMatchSelectWidth={false}
      placeholder={placeholder}
      style={{ width: width }}
      disabled={disabled}
    >
      {options.map((option) => (
        <Select.Option
          value={option.value}
          className="capitalize"
          key={option.value}
        >
          {option.display}
        </Select.Option>
      ))}
    </Select>
  );
};

export default React.memo(ColorSelect);
