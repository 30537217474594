import { useUser } from "hooks/useAuth";
import UserLoading from "components/user-loading";
import LphHomepage from "components/lph-homepage";
import ProviderHomepage from "components/provider-homepage";
import EvaluatorHomepage from "components/evaluator-homepage";

export default function Home() {
  const { user } = useUser({
    redirectTo: "/login",
  });
  // console.log(user);

  if (!user) return <UserLoading />;
  if (user.userProfile.isEvaluator)
    return <EvaluatorHomepage userProfile={user} />;
  if (user.lph) return <LphHomepage />;
  return <ProviderHomepage />;
}
