import * as React from "react";
import { FileTextFilled } from "@ant-design/icons";
import { useState } from "react";
import { CloseCircleOutlined } from "@ant-design/icons/lib";
import { Button, Popconfirm } from "antd";
import { useDeleteDocumentMutation } from "generated/graphql";
import { useUser } from "hooks/useAuth";

function RemoveDocument({ id, hovered }) {
  const [showPopconfirm, setShowPopconfirm] = useState(false);

  const [deleteDocument] = useDeleteDocumentMutation({
    refetchQueries: ["Documents"],
  });
  return (
    <Popconfirm
      title="Are you sure you want to delete this document?"
      onConfirm={() => {
        setShowPopconfirm(false);
        deleteDocument({
          variables: {
            id,
          },
        }).catch((err) => console.log(err));
      }}
      onCancel={() => setShowPopconfirm(false)}
      okText="Yes"
      cancelText="No"
      visible={showPopconfirm}
    >
      <Button
        icon={<CloseCircleOutlined />}
        className={`text-black border-none shadow-none bg-transparent ${
          hovered || showPopconfirm ? "initial" : "hidden"
        } cursor-pointer hover:text-blue-2`}
        onClick={() => setShowPopconfirm(!showPopconfirm)}
        size={"small"}
      >
        <div className="hidden">Delete document</div>
      </Button>
    </Popconfirm>
  );
}

const DocumentCard: React.FC<{
  id: string;
  url: string;
  name: string;
  description: string;
  outcomeLetter?: boolean;
}> = ({ id, url, name, description, outcomeLetter = false }) => {
  const { user } = useUser({});
  const [hovered, setHovered] = useState(false);
  const [focused, setFocused] = useState(false);

  return (
    <a
      id={id}
      href={url}
      target="_blank"
      className="w-full h-full transition ease-out duration-200 bg-white hover:bg-gray-100 focus:bg-gray-100 rounded border border-solid border-gray p-4 flex"
      onFocus={() => {
        setFocused(true);
      }}
      onBlur={() => {
        setFocused(false);
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => {
        setHovered(false);
      }}
    >
      <div className="relative flex w-full">
        <div>
          <FileTextFilled className="fill-current mt-1 mr-2 text-blue-2" />
        </div>
        <div className="w-full">
          <div className="flex justify-between w-full">
            <div
              className={`document-name font-semibold text-base text-blue-2 ${
                hovered || focused ? "underline" : ""
              }`}
            >
              {name}
            </div>
            {user.lph && !outcomeLetter && (
              <RemoveDocument id={id} hovered={hovered} />
            )}
          </div>
          <div className="text-gray">{description}</div>
        </div>
      </div>
    </a>
  );
};

export default DocumentCard;
