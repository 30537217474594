import React from "react";
import { Button, message, Statistic, Table, Tabs } from "antd";
import { useQuestionnairesQuery } from "generated/graphql";
import { useUser } from "hooks/useAuth";
import moment from "moment";
import DeadlineAlert from "./deadline-alert";
import EvaluationTable from "./evaluation-table";
import DownloadResponses from "./download-responses";
import Mobilisation from "components/mobilisation-table";
import EvaluationStatistics from "components/evaluation-statistics";
import client from "utils/client";

function Application() {
  const { user } = useUser({});
  const { data, loading, fetchMore } = useQuestionnairesQuery({
    variables: {
      first: 10,
    },
    notifyOnNetworkStatusChange: true,
  });

  return (
    <div className="flex flex-col">
      <div className="self-end">
        <DeadlineAlert />
      </div>
      <Button
        className="my-4 self-end"
        onClick={() => {
          client("/export_questionnaires/", {
            method: "POST",
            data: {
              member_name: user?.firstName,
              member_email: user?.email,
            },
          })
            .then((data) => {
              if (data.success)
                message.success("Export will be emailed to you shortly");
              else message.error("There was an error");
            })
            .catch(() => {
              message.error("There was an error");
            });
        }}
      >
        Download
      </Button>
      <Table
        loading={loading}
        dataSource={data?.questionnaires?.edges}
        rowKey={({ node }) => node.uuid}
        columns={[
          {
            title: "User name",
            render: ({ node }) => (
              <div>{node.user.provideruserprofile.user.fullName}</div>
            ),
          },
          {
            title: "User email",
            render: ({ node }) => (
              <div>{node.user.provideruserprofile.user.email}</div>
            ),
          },
          {
            title: "Organisation",
            render: ({ node }) => <div>{node.user.applicationName}</div>,
          },
          {
            title: "Registration",
            render: ({ node }) => (
              <div>{moment(node.created)?.format("DD MMMM")}</div>
            ),
          },
          {
            title: "Application status",
            render: ({ node }) => <div>{node.status}</div>,
          },
        ]}
        pagination={false}
      />
      <Statistic
        className="self-end"
        value={data?.questionnaires?.edges?.length}
        suffix={`/ ${data?.questionnaires?.totalCount || 0}`}
      />
      <Button
        className="self-center"
        disabled={!data?.questionnaires?.pageInfo?.hasNextPage}
        onClick={() => {
          fetchMore({
            variables: {
              after: data?.questionnaires?.pageInfo?.endCursor,
            },
          });
        }}
      >
        Load more
      </Button>
    </div>
  );
}

function Evaluation() {
  return (
    <div className="flex flex-col">
      <DownloadResponses />
      <EvaluationStatistics />
      <EvaluationTable />
    </div>
  );
}

export default function LphHomepage() {
  return (
    <>
      <Tabs defaultActiveKey="Mobilisation">
        <Tabs.TabPane tab="Mobilisation" key="Mobilisation">
          <Mobilisation />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Evaluation" key="Evaluation">
          <Evaluation />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Application" key="Application">
          <Application />
        </Tabs.TabPane>
      </Tabs>
    </>
  );
}
