import * as React from "react";
import DocumentCard from "./document-card";
import { Button, Tag } from "antd";
import { FlagFilled } from "@ant-design/icons/lib";
import { ContractAvailable, ContractDownload } from "./contract-download";

export default function OutcomeLetter({ user }) {
  const outcomeLetterStatus =
    user?.userProfile?.procurementuserprofile?.qualificationquestionnaire
      ?.outcomeLetterStatus;
  const legalEntityUUID =
    user?.userProfile?.procurementuserprofile?.legalEntity2021?.uuid;
  const url = `https://${
    process.env.NEXT_PUBLIC_EP_AWS_STORAGE_BUCKET_NAME
  }.s3.eu-west-${
    process.env.NEXT_PUBLIC_EP_AWS_STORAGE_BUCKET_NAME === "carepulse-local"
      ? "2"
      : "1"
  }.amazonaws.com/eprocurement/outcome-letters/${outcomeLetterStatus}-outcome-letters/Pan-London+Domiciliary+Care+AQP+2020+-+${user?.userProfile?.procurementuserprofile?.applicationName
    ?.trim()
    ?.replace(/[/\\|:*"<>]/g, "")
    ?.replace(/ /g, "+")}.pdf`;

  return (
    <div className="flex w-full bg-white rounded p-6 flex-wrap">
      {user?.userProfile?.procurementuserprofile?.qualificationquestionnaire
        ?.contractIssuedNotEmbedded &&
      !user?.userProfile?.procurementuserprofile?.qualificationquestionnaire
        ?.contractSigned ? (
        <ContractAvailable />
      ) : (
        user?.userProfile?.procurementuserprofile?.qualificationquestionnaire
          ?.contractIssuedNotEmbedded &&
        user?.userProfile?.procurementuserprofile?.qualificationquestionnaire
          ?.contractSigned && <ContractDownload />
      )}
      <h2 className="w-full">Your outcome letter</h2>
      <p>
        This letter will be available to download online for a limited time.
        Make sure you have a copy offline.
      </p>
      <DocumentCard
        id={""}
        url={url}
        name={`Pan-London Domiciliary Care AQP 2020 - ${user?.userProfile?.procurementuserprofile?.applicationName
          ?.trim()
          ?.replace(/[/\\|:*"<>]/g, "")}`}
        description={
          "Download link to your Pan-London Domiciliary Care AQP 2020 application outcome letter."
        }
        outcomeLetter
      />

      {(outcomeLetterStatus === "pass" || outcomeLetterStatus === "pass-pcg") &&
        user.userProfile.procurementuserprofile.qualificationquestionnaire
          .mobilisationStatus !== "Submitted" && (
          <Button
            type="primary"
            href={`mobilisation-form/${legalEntityUUID}`}
            className="mt-4"
          >
            Complete Mobilisation Form
          </Button>
        )}
    </div>
  );
}
