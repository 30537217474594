import * as React from "react";
import { Alert, Statistic } from "antd";
import { useSectionList } from "hooks/user-section-list";
import moment from "moment-timezone";
import { useDeadline } from "context/deadline-context";

export default function DeadlineAlert() {
  const { data, loading } = useSectionList();
  const {
    resetTime,
    deadline,
    deadlineExpired,
    daysRemaining,
    minutesRemaining,
    hoursRemaining,
    clarificationQuestionsDeadline,
    clarificationQuestionsSubmittedDate,
    clarificationQuestionsDeadlinePassed,
    clarificationQuestionsHoursRemaining,
    clarificationQuestionsPending,
  } = useDeadline();
  let message;
  let type;

  const readyToSubmitCQ = data?.questionnaire?.sections?.every(
    (section) => section.cqStatus === "finish" || section.cqStatus === null
  );

  const readyToSubmit = data?.questionnaire?.sections?.every(
    (section) => section?.status === "finish"
  );

  if (data?.questionnaire?.dateCompleted) {
    type = "success";
    message = (
      <div className="flex justify-between items-center flex-wrap">
        <div>
          Overall status:{" "}
          <span className="font-bold">
            Submitted on{" "}
            {moment(data?.questionnaire?.dateCompleted)?.format(
              "DD/MM/YYYY HH:mm"
            )}
          </span>
        </div>
      </div>
    );

    // if (clarificationQuestionsDeadline) {
    //   if (clarificationQuestionsSubmittedDate) {
    //     type = "success";
    //     message = (
    //       <div className="flex justify-between items-center flex-wrap">
    //         <div>
    //           <div className="font-bold">
    //             Clarification questions response submitted on{" "}
    //             {clarificationQuestionsSubmittedDate?.format(
    //               "DD/MM/YYYY, HH:mm"
    //             )}
    //           </div>
    //         </div>
    //       </div>
    //     );
    //   } else if (clarificationQuestionsDeadlinePassed) {
    //     type = "error";
    //     message = (
    //       <div className="flex justify-between items-center flex-wrap">
    //         <div>
    //           <div className="font-bold">
    //             Clarification questions deadline passed
    //           </div>
    //         </div>
    //       </div>
    //     );
    //   } else if (clarificationQuestionsPending) {
    //     type = "warning";
    //     message = (
    //       <div className="flex justify-between items-center flex-wrap">
    //         <div>
    //           <div className="font-bold">
    //             Clarification questions{" "}
    //             {readyToSubmitCQ ? "ready to submit" : "requires response"}
    //           </div>{" "}
    //           <div className="mt-2">
    //             Submission deadline:{" "}
    //             <span className="font-bold">
    //               {clarificationQuestionsDeadline?.format("DD/MM/YYYY HH:mm")}
    //             </span>
    //           </div>
    //         </div>
    //         <Statistic.Countdown
    //           title="Time remaining"
    //           className={
    //             clarificationQuestionsHoursRemaining > 8 ? "hidden" : ""
    //           }
    //           //@ts-ignore
    //           value={clarificationQuestionsDeadline}
    //           onFinish={() => {
    //             resetTime();
    //           }}
    //         />
    //       </div>
    //     );
    //   } else if (clarificationQuestionsSubmittedDate) {
    //     type = "success";
    //     message = (
    //       <div className="flex justify-between items-center flex-wrap">
    //         <div>
    //           <div className="font-bold">
    //             Clarification questions response submitted on{" "}
    //             {clarificationQuestionsSubmittedDate?.format(
    //               "DD/MM/YYYY, HH:mm"
    //             )}
    //           </div>
    //         </div>
    //       </div>
    //     );
    //   }
    // }
  } else if (deadlineExpired) {
    type = "error";
    message = (
      <div className="flex justify-between items-center flex-wrap">
        <div>
          Overall status:{" "}
          <span className="font-bold">Application deadline expired</span>
        </div>
      </div>
    );
  } else {
    type = "warning";
    message = (
      <div className="flex justify-between items-center flex-wrap">
        <div>
          Overall status:{" "}
          <span className="font-bold">
            {readyToSubmit ? "Ready to submit" : "Not ready to submit"}
          </span>
        </div>
        <div>
          <div>
            Deadline:{" "}
            <span className="font-bold">
              {deadline.format("DD/MM/YYYY HH:mm")}
            </span>
          </div>
          Time remaining to submit:{" "}
          {hoursRemaining < 1 ? (
            <span className="font-bold">
              {minutesRemaining} minute{minutesRemaining !== 1 && "s"}
            </span>
          ) : hoursRemaining < 48 ? (
            <span className="font-bold">
              {hoursRemaining} hour{hoursRemaining !== 1 && "s"}
            </span>
          ) : (
            <span className="font-bold">
              {daysRemaining} day{daysRemaining !== 1 && "s"}
            </span>
          )}
        </div>
      </div>
    );
  }

  return (
    <Alert
      className="mb-4"
      type={type}
      message={loading ? <div>Loading...</div> : message}
    />
  );
}
