import * as React from "react";
import { Button, message, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons/lib";
import {
  useCommissionerContactInformationQuery,
  useGenerateContractMutation,
  useIssueContractMutation,
  useIssueNotEmbeddedContractMutation,
  useMobilisationFormLazyQuery,
  useSignContractMutation,
  useUpdateSignatureRequestMutation,
} from "../generated/graphql";
import { useEffect, useState } from "react";
import client from "../utils/client";

export const ContractReturnsColumn = ({ node, signatureRequest }) => {
  if (
    node.outcomeLetterStatus === "fail" ||
    node.outcomeLetterStatus === "withdrawn"
  ) {
    return <div>N/A</div>;
  } else if (!node.contractIssued && !node.contractIssuedNotEmbedded) {
    return <div>-</div>;
  } else if (node.contractSigned) {
    return (
      <div
        style={{
          background: "rgba(93, 164, 35, 0.5)",
          borderRadius: 12,
          padding: "2px 12px",
          alignSelf: "center",
          display: "inline-block",
        }}
      >
        Signed
      </div>
    );
  } else if (!node.contractSigned) {
    return (
      <div
        style={{
          background: "rgba(245, 97, 34, 0.5)",
          borderRadius: 12,
          padding: "2px 12px",
          alignSelf: "center",
          display: "inline-block",
        }}
      >
        Unsigned
      </div>
    );
  }
};

const downloadFile = async (contractURL, fileName) => {
  await fetch(contractURL, {
    method: "GET",
  })
    .then((res) => res.blob())
    .then((blob) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
};

export const downloadSignedContract = (
  contractURL,
  legalEntityName,
  signatureRequestId
) => {
  message.loading({
    content: "Downloading signed contract",
    duration: 0,
    key: "downloading",
  });
  fetch(`/api/download-signed-contract`, {
    method: "POST",
    body: JSON.stringify({
      signatureRequestId: signatureRequestId,
      legalEntityName: legalEntityName?.trim().replace(/[/\\|:*"<>]/g, ""),
    }),
  })
    .then((res) => res.json())
    .then(async (res) => {
      if (res.response === "File exists") {
        downloadFile(
          contractURL,
          `Pan-London Domiciliary Care AQP 2020 Contract - ${legalEntityName
            ?.trim()
            .replace(/[/\\|:*"<>]/g, "")}.pdf`
        )
          .then(() => {
            // @ts-ignore
            message.destroy("downloading");
            message.success("File downloaded");
          })
          .catch((err) => {
            console.log("downloadFile error: ", err);
            // @ts-ignore
            message.destroy("downloading");
            message.error("There was an error while downloading file");
          });
      } else if (res.response === "Uploaded") {
        fetch(`/api/check-s3-file-exists`, {
          method: "POST",
          body: JSON.stringify({
            key: `eprocurement/contracts/signed/${legalEntityName
              ?.trim()
              .replace(/[/\\|:*"<>]/g, "")}.pdf`,
          }),
        }).then((res) => {
          if (res.status === 200) {
            downloadFile(
              contractURL,
              `Pan-London Domiciliary Care AQP 2020 Contract - ${legalEntityName
                ?.trim()
                .replace(/[/\\|:*"<>]/g, "")}.pdf`
            )
              .then(() => {
                // @ts-ignore
                message.destroy("downloading");
                message.success("File downloaded");
              })
              .catch((err) => {
                // @ts-ignore
                message.destroy("downloading");
                console.log("downloadFile error: ", err);
                message.error("There was an error while downloading file");
              });
          } else if (res.status === 409) {
            // @ts-ignore
            message.destroy("downloading");
            message.warn("Processing file. Please try again later.");
          }
        });
      } else if (res.response === "Processing") {
        // @ts-ignore
        message.destroy("downloading");
        message.warn("Processing file. Please try again later.");
      }
    })
    .catch((err) => {
      // @ts-ignore
      message.destroy("downloading");
      console.log(err);
      message.error("There was an error.");
    });
};

export const ContractPDFColumn = ({ node }) => {
  const [disabled, setDisabled] = useState(false);

  const contractURL = `https://${
    process.env.NEXT_PUBLIC_EP_AWS_STORAGE_BUCKET_NAME
  }.s3.eu-west-${
    process.env.NEXT_PUBLIC_EP_AWS_STORAGE_BUCKET_NAME === "carepulse-local"
      ? "2"
      : "1"
  }.amazonaws.com/eprocurement/contracts/signed/${node?.user?.legalEntity2021?.name
    ?.trim()
    .replace(/[/\\|:*"<>]/g, "")
    .replace(/ /g, "+")}.pdf`;

  const reviewContractURL = `https://${
    process.env.NEXT_PUBLIC_EP_AWS_STORAGE_BUCKET_NAME
  }.s3.eu-west-${
    process.env.NEXT_PUBLIC_EP_AWS_STORAGE_BUCKET_NAME === "carepulse-local"
      ? "2"
      : "1"
  }.amazonaws.com/eprocurement/contracts/review/${node?.user?.legalEntity2021?.name
    ?.trim()
    .replace(/[/\\|:*"<>]/g, "")
    .replace(/ /g, "+")}.pdf`;

  if (
    node.outcomeLetterStatus === "pass" ||
    node.outcomeLetterStatus === "pass-pcg"
  ) {
    return (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {node.contractIssued || node.contractIssuedNotEmbedded ? (
            node.contractSigned ? (
              <Button
                size={"small"}
                onClick={() =>
                  downloadSignedContract(
                    contractURL,
                    node.user.legalEntity2021.name,
                    node.signatureRequestId
                  )
                }
              >
                Download
              </Button>
            ) : (
              <div
                style={{
                  background: "rgba(0, 0, 0, 0.04)",
                  borderRadius: 12,
                  padding: "2px 12px",
                  alignSelf: "center",
                }}
              >
                Issued
              </div>
            )
          ) : node.mobilisationStatus === "Submitted" ? (
            <>
              <GenerateContract
                node={node}
                disabled={disabled}
                setDisabled={setDisabled}
              />
              {node.contractGenerated && (
                <>
                  <Button
                    size={"small"}
                    style={{ marginTop: 6 }}
                    disabled={disabled}
                    onClick={() => {
                      setDisabled(true);
                      message.loading({
                        content: "Downloading",
                        duration: 0,
                        key: "downloading",
                      });
                      fetch(`/api/check-s3-file-exists`, {
                        method: "POST",
                        body: JSON.stringify({
                          key: `eprocurement/contracts/review/${node.user.legalEntity2021.name
                            ?.trim()
                            .replace(/[/\\|:*"<>]/g, "")}.pdf`,
                        }),
                      }).then((res) => {
                        if (res.status === 200) {
                          downloadFile(
                            reviewContractURL,
                            `${node.user.legalEntity2021.name
                              ?.trim()
                              .replace(/[/\\|:*"<>]/g, "")}.pdf`
                          )
                            .then(() => {
                              setDisabled(false);
                              // @ts-ignore
                              message.destroy("downloading");
                              message.success("File downloaded");
                            })
                            .catch((err) => {
                              setDisabled(false);
                              // @ts-ignore
                              message.destroy("downloading");
                              console.log("downloadFile error: ", err);
                              message.error(
                                "There was an error while downloading file"
                              );
                            });
                        } else if (res.status === 409) {
                          setDisabled(false);
                          // @ts-ignore
                          message.destroy("downloading");
                          message.warn(
                            "File isn't ready for download yet. Please try again later."
                          );
                        }
                      });
                    }}
                  >
                    Review
                  </Button>
                  {/*<IssueEmbeddedContract*/}
                  {/*  node={node}*/}
                  {/*  disabled={disabled}*/}
                  {/*  setDisabled={setDisabled}*/}
                  {/*/>*/}
                  <IssueNonEmbeddedContract
                    node={node}
                    disabled={disabled}
                    setDisabled={setDisabled}
                  />
                </>
              )}
            </>
          ) : (
            <div>-</div>
          )}
        </div>
      </>
    );
  } else return <div>N/A</div>;
};

const GenerateContract: React.FC<{
  node: any;
  disabled: boolean;
  setDisabled: any;
}> = ({ node, disabled, setDisabled }) => {
  const [getData, { loading, data }] = useMobilisationFormLazyQuery({
    variables: {
      uuid: node?.user?.legalEntity2021?.uuid,
    },
    fetchPolicy: "network-only",
  });
  const [mutate] = useGenerateContractMutation({
    variables: {
      uuid: node?.user?.legalEntity2021?.uuid,
      contractGenerated: true,
    },
  });

  useEffect(() => {
    if (!loading && data) {
      fetch(`/api/generate-contract-pdf`, {
        method: "POST",
        body: JSON.stringify(data?.mobilisation2021),
      })
        .then((res) => {
          if (res.status === 200) {
            fetch(`/api/merge-contract-pdf`, {
              method: "POST",
              body: JSON.stringify({
                legalEntityName: data?.mobilisation2021?.name,
              }),
            })
              .then((res) => {
                if (res.status === 200) {
                  fetch(`/api/upload-for-review`, {
                    method: "POST",
                    body: JSON.stringify({
                      legalEntityName: data?.mobilisation2021?.name,
                    }),
                  })
                    .then((res) => {
                      if (res.status === 200) {
                        mutate()
                          .then(() => {
                            setDisabled(false);
                            message.destroy("loading-contract");
                            message.success(
                              `Contract has been successfully generated.`
                            );
                          })
                          .catch(() => {
                            setDisabled(false);
                            message.destroy("loading-contract");
                            message.error(
                              `There was an error while generating the contract.`
                            );
                          });
                      } else {
                        setDisabled(false);
                        message.destroy("loading-contract");
                        message.error(
                          `There was an error while generating the contract.`
                        );
                      }
                    })
                    .catch(() => {
                      setDisabled(false);
                      message.destroy("loading-contract");
                      message.error(
                        `There was an error while generating the contract.`
                      );
                    });
                } else {
                  setDisabled(false);
                  message.destroy("loading-contract");
                  message.error(
                    `There was an error while generating the contract.`
                  );
                }
              })
              .catch(() => {
                setDisabled(false);
                message.destroy("loading-contract");
                message.error(
                  `There was an error while generating the contract.`
                );
              });
          } else {
            setDisabled(false);
            message.destroy("loading-contract");
            message.error(`There was an error while generating the contract.`);
          }
        })
        .catch(() => {
          setDisabled(false);
          message.destroy("loading-contract");
          message.error(`There was an error generating the contract PDF.`);
        });
    }
  }, [loading]);

  return (
    <Button
      size={node?.contractGenerated ? "small" : "middle"}
      type={node?.contractGenerated ? "default" : "primary"}
      onClick={() => {
        getData();
        setDisabled(true);
        return message.loading({
          content: "Generating contract.",
          duration: 0,
          key: "loading-contract",
        });
      }}
      disabled={disabled}
    >
      Generate
    </Button>
  );
};

const IssueEmbeddedContract: React.FC<{
  node: any;
  disabled: boolean;
  setDisabled: any;
}> = ({ node, disabled, setDisabled }) => {
  const [issueContract, { loading: loadingIssue }] = useIssueContractMutation({
    variables: {
      uuid: node?.user?.legalEntity2021?.uuid,
      contractIssued: true,
    },
  });
  const [
    updateSignatureRequest,
    { loading: loadingUpdate },
  ] = useUpdateSignatureRequestMutation();

  const confirmIssueContract = (node) => {
    return Modal.confirm({
      title: "Are you sure you want to issue this contract?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        message.loading({
          content: "Issuing contract.",
          duration: 0,
          key: "loading-issue",
        });
        setDisabled(true);
        fetch(`/api/generate-hellosign-request`, {
          method: "POST",
          body: JSON.stringify({
            legalEntityName: node.user.legalEntity2021.name,
            signers: [
              {
                email_address: node.user.legalEntity2021.representativeEmail,
                name: node.user.legalEntity2021.signatoryName,
              },
            ],
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            updateSignatureRequest({
              variables: {
                uuid: node?.user?.legalEntity2021?.uuid,
                signatureId: res.signatureId,
                signatureRequestId: res.signatureRequestId,
              },
            })
              .then(() => {
                issueContract()
                  .then(() => {
                    client("/api/send_contract_issued_email/", {
                      method: "POST",
                      data: {
                        uuid: node?.user?.legalEntity2021?.uuid,
                      },
                    })
                      .then(() => {
                        setDisabled(false);
                        message.destroy("loading-issue");
                        message.success("Contract successfully issued.");
                      })
                      .catch((err) => {
                        console.log(err);
                        setDisabled(false);
                        message.destroy("loading-issue");
                        message.error(
                          "There was an error while issuing the contract."
                        );
                      });
                  })
                  .catch(() => {
                    setDisabled(false);
                    message.destroy("loading-issue");
                    message.error(
                      "There was an error while issuing the contract."
                    );
                  });
              })
              .catch(() => {
                setDisabled(false);
                message.destroy("loading-issue");
                message.error("There was an error while issuing the contract.");
              });
          })
          .catch(() => {
            setDisabled(false);
            message.destroy("loading-issue");
            message.error("There was an error while issuing the contract.");
          });
      },
      onCancel() {},
    });
  };

  return (
    <Button
      size={"small"}
      style={{ marginTop: 6 }}
      type={"primary"}
      onClick={() => confirmIssueContract(node)}
      disabled={loadingIssue || loadingUpdate || disabled}
    >
      Issue embedded
    </Button>
  );
};

const IssueNonEmbeddedContract: React.FC<{
  node: any;
  disabled: boolean;
  setDisabled: any;
}> = ({ node, disabled, setDisabled }) => {
  const [
    issueContract,
    { loading: loadingIssue },
  ] = useIssueNotEmbeddedContractMutation({
    variables: {
      uuid: node?.user?.legalEntity2021?.uuid,
      contractIssuedNotEmbedded: true,
    },
  });
  const [
    updateSignatureRequest,
    { loading: loadingUpdate },
  ] = useUpdateSignatureRequestMutation();

  const confirmIssueContract = (node) => {
    return Modal.confirm({
      title: "Are you sure you want to issue this non-embedded contract?",
      content:
        "This contract will be issued using the default HelloSign email and will not use the embedded functionality.",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        message.loading({
          content: "Issuing contract.",
          duration: 0,
          key: "loading-issue",
        });
        setDisabled(true);
        fetch(`/api/generate-non-embedded-hellosign-request`, {
          method: "POST",
          body: JSON.stringify({
            legalEntityName: node.user.legalEntity2021.name
              .trim()
              .replace(/[/\\|:*"<>]/g, ""),
            signers: [
              {
                email_address:
                  process.env.NEXT_PUBLIC_EP_AWS_STORAGE_BUCKET_NAME ===
                  "carepulse-local"
                    ? "lph.quality@nhs.net"
                    : node.user.legalEntity2021.representativeEmail,
                name: node.user.legalEntity2021.signatoryName,
              },
            ],
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            updateSignatureRequest({
              variables: {
                uuid: node?.user?.legalEntity2021?.uuid,
                signatureId: res.signatureId,
                signatureRequestId: res.signatureRequestId,
              },
            })
              .then(() => {
                issueContract()
                  .then(() => {
                    setDisabled(false);
                    message.destroy("loading-issue");
                    message.success("Contract successfully issued.");
                  })
                  .catch(() => {
                    setDisabled(false);
                    message.destroy("loading-issue");
                    message.error(
                      "There was an error while issuing the contract."
                    );
                  });
              })
              .catch(() => {
                setDisabled(false);
                message.destroy("loading-issue");
                message.error("There was an error while issuing the contract.");
              });
          })
          .catch((err) => {
            console.log("API ERR: ", err);
            setDisabled(false);
            message.destroy("loading-issue");
            message.error("There was an error while issuing the contract.");
          });
      },
      onCancel() {},
    });
  };

  return (
    <Button
      size={"small"}
      style={{ marginTop: 6 }}
      type={"primary"}
      onClick={() => confirmIssueContract(node)}
      disabled={loadingIssue || loadingUpdate || disabled}
    >
      Issue
    </Button>
  );
};

export const CommissionerContractAndEmails = () => {
  const { data } = useCommissionerContactInformationQuery();

  function confirmCommissionerContracts() {
    Modal.confirm({
      width: 600,
      title:
        "Are you sure you would like to send the contract out to commissioners?",
      content: (
        <>
          <div>
            After confirmation, the commissioners will all receive an email to
            E-Sign the contract.
          </div>
        </>
      ),
      onOk() {
        if (data) {
          const signers = data?.commissionersContactInformation?.map(
            (contact) => {
              return {
                email_address: contact.emailAddress,
                name: contact.name,
              };
            }
          );
          const sortedSigners = signers.sort(function (a, b) {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
          message.loading({
            content: "Sending out commissioner contract",
            duration: 0,
            key: "commissioner-loading",
          });
          fetch(`/api/generate-commissioners-hellosign-request`, {
            method: "POST",
            body: JSON.stringify({
              signers: sortedSigners,
            }),
          })
            .then((res) => {
              if (res.status === 200) {
                message.destroy("commissioner-loading");
                message.success(
                  "You have successfully sent the commissioner contract."
                );
              } else {
                message.destroy("commissioner-loading");
                message.error(
                  "There was an error while sending the commissioner contract signature request"
                );
              }
            })
            .catch((err) => {
              console.log(err);
              message.destroy("commissioner-loading");
              message.error(
                "There was an error while sending the commissioner contract signature request"
              );
            });
        }
      },
      onCancel() {},
    });
  }

  function confirmEmbeddedEmailReminders() {
    Modal.confirm({
      width: 600,
      title:
        "Are you sure you would like to send email reminders to all providers with embedded signature requests?",
      content: (
        <>
          <div>
            After confirmation, providers that have not yet signed the contract
            will receive email reminders.
          </div>
        </>
      ),
      onOk() {
        message.loading({
          content: "Sending out reminder emails",
          duration: 0,
          key: "reminder-loading",
        });
        client("/api/send_contract_reminder_emails/", {
          method: "POST",
        })
          .then((res) => {
            if (res.status === 200) {
              message.destroy("reminder-loading");
              message.success("Successfully sent email reminders");
            }
          })
          .catch((err) => {
            console.log(err);
            message.destroy("reminder-loading");
            message.error(
              "There was an error while sending out email reminders"
            );
          });
      },
      onCancel() {},
    });
  }

  function confirmNonEmbeddedEmailReminders() {
    Modal.confirm({
      width: 600,
      title:
        "Are you sure you would like to send email reminders to all providers with non-embedded contracts?",
      content: (
        <>
          <div>
            After confirmation, providers with non-embedded signature requests
            that have not yet signed the contract will receive reminder emails.
          </div>
        </>
      ),
      onOk() {
        message.loading({
          content: "Sending out reminder emails",
          duration: 0,
          key: "reminder-loading",
        });
        fetch(`/api/send-non-embedded-email-reminders`, {
          method: "POST",
        })
          .then((res) => {
            if (res.status === 200) {
              message.destroy("reminder-loading");
              message.success(
                "Successfully sent reminder emails for non-embedded contracts."
              );
            } else if (res.status === 400) {
              message.destroy("reminder-loading");
              message.error(
                "Error while sending reminder emails for non-embedded contracts."
              );
            }
          })
          .catch(() => {
            message.destroy("reminder-loading");
            message.error(
              "Error while sending reminder emails for non-embedded contracts."
            );
          });
      },
      onCancel() {},
    });
  }

  return (
    <>
      <h4 className="mt-4">Contracts</h4>
      <div className="flex flex-wrap">
        <Button
          onClick={confirmCommissionerContracts}
          className="mr-4"
          disabled
        >
          Send commissioner contracts
        </Button>
        {/*<Button onClick={confirmEmbeddedEmailReminders} className="mr-4">*/}
        {/*  Send embedded email reminders*/}
        {/*</Button>*/}
        <Button onClick={confirmNonEmbeddedEmailReminders}>
          Send email reminders
        </Button>
      </div>
    </>
  );
};
